<template>
  <v-container fluid>
    <v-row dense>
      <v-expand-transition>
        <v-col cols="12" v-if="iFields.length === 0">
          <v-alert type="info" prominent text>
            You do not have any custom fields set up for this event
          </v-alert>
        </v-col>
      </v-expand-transition>
      <v-col cols="12" v-for="(field, i) in iFields" :key="`field-${i}`">
        <v-card>
          <v-card-text>
            <custom-field-designer
              :field="field"
              :disabled="!currentI && currentI !== i"
              :tournament="tournament"
              :touched="touched"
            ></custom-field-designer>
          </v-card-text>
          <v-expand-transition>
            <v-card-actions v-if="currentI === i">
              <v-btn
                color="success"
                @click.stop="saveClick(false)"
                :disabled="touched && !field.valid"
                :loading="loading"
              >save</v-btn>
              <v-btn
                color="color3Text color3--text"
                @click.stop="deleteClick"
                :loading="loading"
              >delete</v-btn>
              <v-btn
                color="error"
                @click.stop="initFields"
                :disabled="loading"
              >cancel</v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-btn
                color="color3 color3Text--text"
                @click.stop="currentI = i"
                :disabled="!!(currentI && currentI !== i)"
              >edit</v-btn>
            </v-card-actions>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          :disabled="dirty"
          color="color3 color3Text--text"
          @click.stop="addField"
        >Add a custom field</v-btn>
      </v-col>
    </v-row>
    <vbl-confirm-dialog
      :ask="confirmDelete"
      :loading="loading"
      @confirm="onDeleteConfirm"
      @deny="onDeleteDeny"
    ></vbl-confirm-dialog>

  </v-container>
</template>

<script>
import AdditionalField from '@/classes/AdditionalField'
import CustomFieldDesigner from '@/components/Forms/CustomFieldDesigner'

export default {
  props: ['tournament', 'active'],
  data () {
    return {
      iFields: [],
      currentI: null,
      touched: false,
      loading: false,
      confirmDelete: false
    }
  },
  computed: {
    eventCustomFields () {
      const fields = this.tournament && this.tournament.jProps && this.tournament.jProps.customFields

      return fields || []
    },
    currentField () {
      return this.iFields.length > this.currentI ? this.iFields[this.currentI] : null
    },
    dirty () {
      return this.iFields.length > this.eventCustomFields.length && !this.loading
    }
  },
  methods: {
    initFields () {
      this.currentI = null
      this.touched = false
      this.confirmDelete = false
      this.iFields = this.eventCustomFields.length > 0 ? this.eventCustomFields.map(m => new AdditionalField(JSON.parse(JSON.stringify(m)))) : []
    },
    addField () {
      this.currentI = this.iFields.length
      this.iFields.push(new AdditionalField())
    },
    saveClick (force) {
      if (!force) {
        if (!this.currentField) return
        if (!this.currentField.valid) {
          this.touched = true
          return
        }
      }
      this.loading = true
      if (!this.tournament.jProps) this.tournament.jProps = {}
      this.tournament.jProps.customFields = this.iFields.map(m => m.dto)
      this.tournament.saveJProps()
        .then(r => {
          this.$nextTick(() => {
            this.initFields()
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    deleteClick () {
      this.confirmDelete = true
    },
    onDeleteConfirm () {
      if (this.currentI !== null) {
        this.iFields.splice(this.currentI, 1)
        this.saveClick(true)
      }
    },
    onDeleteDeny () {
      this.confirmDelete = false
    }
  },
  watch: {
    active: 'initFields',
    eventCustomFields: {
      handler (v) {
        if (!this.loading) {
          this.initFields()
        }
      },
      deep: true
    }
  },
  components: {
    CustomFieldDesigner
  },
  mounted () {
    this.initFields()
  }
}
</script>
