<template>
  <v-container fluid>
    <v-row dense>
      <!-- field label -->
      <v-col cols="12" sm="6">
        <v-text-field
          label="Question*"
          v-model="field.label"
          color="color3"
          :error-messages="labelErrors"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <!-- field bools -->
      <v-col cols="12" sm="6" class="d-flex">
        <v-checkbox
          label="Ask Each Player"
          v-model="field.perPlayer"
          :value="true"
          color="success"
          class="shrink mr-3"
          :disabled="disabled"
        ></v-checkbox>
        <v-checkbox
          label="Make Required"
          v-model="field.required"
          :value="true"
          color="success"
          class="shrink"
          :disabled="disabled"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <!-- field type -->
      <v-col cols="12" sm="6">
        <v-select
          label="Answer Type"
          :items="typeOptions"
          v-model="field.type"
          color="color3"
          item-color="color3"
          :disabled="disabled"
        ></v-select>
        <!-- Drop down options -->
        <v-expand-transition>
          <div v-if="field.type === 'select'">
            <div>Drop Down Choices</div>
            <v-alert :type="touched ? 'error' : 'info'" prominent v-if="field.items.length < 2" text transition="scale-transition">
              Please add options to the drop down list
            </v-alert>
            <v-list dense>
              <v-list-item
              v-for="(item, i) in field.items"
              :key="i"
              >
                <v-list-item-content>
                  <v-chip label>
                    {{item}}
                  </v-chip>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn
                    color="color3"
                    text icon small
                    @click.stop="editOption(i)"
                    :disabled="disabled"
                  >
                    <v-icon small color="color3">fas fa-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="error"
                    text icon small
                    @click.stop="deleteOption(i)"
                    :disabled="disabled"
                  >
                    <v-icon small color="error">fas fa-trash</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <div class="text-center">
              <v-btn color="color3" text @click.stop="addOption">Add Option</v-btn>
              <vbl-confirm-dialog
                :ask="confirmDelete"
                @confirm="onDeleteConfirm"
                @deny="onDeleteDeny"
              ></vbl-confirm-dialog>
              <v-dialog
                v-model="optDialog"
                max-width="500px"
                persistent
                transition="dialog-transition"
              >
                <v-card>
                  <v-card-text>
                    <v-text-field
                      :label="`Option ${optI + 1}`"
                      v-model="selectedOption"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn color="success white--text" @click.stop="saveOption">save</v-btn>
                    <v-btn color="error white--text" @click.stop="optDialog = false">cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </v-expand-transition>
      </v-col>
      <!-- division select -->
      <v-col cols="12" sm="6">
        <v-select
          :items="divisionOptions"
          v-model="field.divisions"
          label="Divisions"
          color="color3"
          item-color="color3"
          multiple
          hint="Select the divisions to ask this question to"
          persistent-hint
          :error-messages="divErrors"
          :disabled="disabled"
        >
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="toggleDivisions"
            >
              <v-list-item-action>
                <v-icon :color="likesAllFruit || likesSomeFruit ? 'color3' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>

      </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdditionalField from '@/classes/AdditionalField'
import { firstBy } from 'thenby'

export default {
  props: ['field', 'tournament', 'touched', 'disabled'],
  data () {
    return {
      dialog: false,
      iField: null,
      optDialog: false,
      optI: null,
      selectedOption: null,
      confirmDelete: false
    }
  },
  computed: {
    labelErrors () {
      return this.touched && !this.field.label ? ['A question is required'] : []
    },
    divErrors () {
      return this.field.divisions.length === 0 ? ['A division is required'] : []
    },
    likesAllFruit () {
      return this.field.divisions.includes('*') || (this.field.divisions.length === this.divisionOptions.length && !this.field.divisions.includes('*'))
    },
    likesSomeFruit () {
      return this.field.divisions.length > 0 && !this.likesAllFruit
    },
    icon () {
      if (this.likesAllFruit) return 'fas fa-times-square'
      if (this.likesSomeFruit) return 'fas fa-minus-square'
      return 'far fa-square'
    },
    typeOptions () {
      return [
        { text: 'Drop Down List', value: 'select' },
        { text: '1 Line Free Text', value: 'textbox' },
        { text: 'Multi-Line Free Text', value: 'textarea' }
      ].sort(firstBy('text'))
    },
    divisionOptions () {
      return this.tournament.publicDivisions.map(m => {
        return {
          text: m.name,
          value: m.id
        }
      })
    }
  },
  methods: {
    initField () {
      this.iField = new AdditionalField(JSON.parse(JSON.stringify(this.field.dto)))
    },
    addOption () {
      this.optI = this.field.items.length
      this.selectedOption = null
      this.optDialog = true
    },
    editOption (i) {
      this.optI = i
      this.selectedOption = `${this.field.items[i]}`
      this.optDialog = true
    },
    deleteOption (i) {
      this.optI = i
      this.confirmDelete = true
    },
    onDeleteConfirm () {
      this.field.items.splice(this.optI, 1)
      this.onDeleteDeny()
    },
    onDeleteDeny () {
      this.confirmDelete = false
    },
    saveOption () {
      if (this.optI < this.field.items.length) {
        this.field.items[this.optI] = this.selectedOption
      } else {
        this.field.items.push(this.selectedOption)
      }
      this.optDialog = false
    },
    toggleDivisions () {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.field.divisions = []
        } else {
          this.selectAllDivisions()
        }
      })
    },
    selectAllDivisions () {
      this.field.divisions = [...this.divisionOptions.map(m => m.value)]
    }
  },
  mounted () {
    this.initField()
    if (this.likesAllFruit) this.selectAllDivisions()
  }
}
</script>
